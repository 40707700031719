<template>
    <v-menu offset-overflow offset-y bottom :nudge-bottom="5" max-width="324" v-model="menu" @keydown="onKeyDown" :light="true">
        <template v-slot:activator="{ on, attrs }">
            <slot />
        </template>

        <div class="command-menu-active">
            <div v-for="(item, index) in _items" class="item-command-menu" :class="{focus: index == selectedItem}" :key="item.id" @click="$emit('select', item)">
                <div class="icon">
                    <img :src="item.img" />
                </div>

                <div class="content-item">
                    <div class="title-option">{{ item.label }}</div>
                </div>
            </div>
        </div>
    </v-menu>
</template>
  
<script>

export default {
    props: ['viewMenu'],
    data() {
        return {
            items: [
                {
                    id: "task",
                    tag: 'task',
                    label: "Task",
                    img: "/build/images/square-check-light.svg",
                },
                {
                    id: "text",
                    tag: "p",
                    label: "Text",
                    img: "/build/images/font-case-light.svg",
                },
                {
                    id: "heading-1",
                    tag: "h1",
                    label: "Heading 1",
                    img: "/build/images/h1-light.svg",
                },
                {
                    id: "heading-2",
                    tag: "h2",
                    label: "Heading 2",
                    img: "/build/images/h2-light.svg",
                },
                {
                    id: "heading-3",
                    tag: "h3",
                    label: "Heading 3",
                    img: "/build/images/h3-light.svg",
                },
                {
                    id: "file",
                    tag: 'file',
                    label: "Fisier",
                    img: "/build/images/upload-file.svg",
                },
                {
                    id: "project",
                    tag: 'project',
                    label: "Proiect",
                    img: "/build/images/projects.svg",
                },
                {
                    id: "add-project",
                    tag: 'project',
                    label: "Proiect nou",
                    img: "/build/images/project-add.svg",
                },
            ],
            selectedItem: 0,
            command: "",
            menu: false,
        };
    },
    computed: {
        value() {
            return this.viewMenu
        },
        _items() {
            if(!this.command.length) return this.items;

            return this.items.sort((a, b) => {
                // Sort results by matching name with keyword position in name
                if(
                    a.tag.toLowerCase().indexOf(this.command.toLowerCase()) > b.tag.toLowerCase().indexOf(this.command.toLowerCase()) || 
                    a.label.toLowerCase().indexOf(this.command.toLowerCase()) > b.label.toLowerCase().indexOf(this.command.toLowerCase())
                ) {
                    return -1;
                } else if (
                    a.tag.toLowerCase().indexOf(this.command.toLowerCase()) < b.tag.toLowerCase().indexOf(this.command.toLowerCase()) || 
                    a.label.toLowerCase().indexOf(this.command.toLowerCase()) < b.label.toLowerCase().indexOf(this.command.toLowerCase())
                ) {
                    return 1;
                }
            })
        }
    },
    watch: {
        viewMenu() {
            this.menu = this.value;
        },
        menu(newValue, oldValue) {
            if(newValue == false) {
                this.command = "";
                this.$emit('update:viewMenu', false)
            }
        }
    },
    methods: {
        parentNodeBlock(e) {
            console.log(e)

            return 
        },
        onKeyDown(e) {
            if (!this.value) {
                return;
            }

            // Detect if key was 0-9 or a-z
            if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 65 && e.keyCode <= 90)) {
                this.command = this.command + e.key;
            } else {
                switch (e.key) {
                    case "Enter":
                        e.preventDefault();
                        if(this._items[this.selectedItem]) {
                            this.$emit("select", this._items[this.selectedItem]);
                        }
                        this.menu = false;
                        break;

                    case "Backspace":
                        if (!this.command) {
                            this.menu = false;
                        }

                        this.command = this.command.substring(0, this.command.length - 1);
                        break;

                    case "ArrowUp":
                        e.preventDefault();
                        const prevSelected =
                            this.selectedItem == 0
                                ? this._items.length - 1
                                : this.selectedItem - 1;
                        this.selectedItem = prevSelected;
                        break;

                    case "ArrowDown":
                        e.preventDefault();
                        const nextSelected =
                            this.selectedItem === this._items.length - 1
                                ? 0
                                : this.selectedItem + 1;
                        this.selectedItem = nextSelected;
                        break;

                    case " ":
                        this.menu = false;
                        break;

                    default:
                        this.menu = false;
                        break;
                }
            }
        },
    }
};
</script>
  